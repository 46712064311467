<template>
	<div class="innerbox">
		<div class="hebingbox" @click="hebing">
			合并开票
		</div>
		<div class="buyrecord">
			<el-card>
				<el-table  ref="table" :data="tableData" style="width: 100%" @selection-change="handleSelectionChange">
					<el-table-column type="selection" width="50" :selectable="selectable"></el-table-column>
					<el-table-column fixed prop="goods_name" label="订单名称">
						<template slot-scope="scope">

							<span class="iconf" v-if="scope.row.platform === 1">
								网页
							</span>
							<span class="iconf" v-if="scope.row.platform === 2">
								小程序
							</span>
							<span style="margin-left: 10px">{{ scope.row.goods_name }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="price" label="金额">

					</el-table-column>
					<el-table-column prop="created_at" label="交易时间">
					</el-table-column>
					<el-table-column label="操作" width="200">

						<template slot-scope="scope">
							<div class="actionbox">
								<el-button type="text" :class="{'canbill':scope.row.can_bill === false}" @click="changebill(scope.row)" size="small">{{
			scope.row.bill_status_txt }}</el-button>

								<el-button v-if="scope.row.download" @click="repeatdownload(scope.row)" type="text"
									size="small">重新下载
								</el-button>
								<el-button class="del" type="text" @click="deleteRow(scope.row)"
									size="small">删除</el-button>
							</div>

						</template>
					</el-table-column>
				</el-table>
				<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="page" :page-sizes="[20, 50, 100]" :page-size="size"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</el-card>
		</div>
		<el-dialog width="580px" title="开票申请" :visible.sync="dialogFormVisible">
			<div class="jine">
					<div class="jinebox"><span>开票金额：</span><span class="price">{{ price }}元</span></div>

				</div>
				<div class="type">
					<div :class="{ 'el-radio-button': true, 'el-radio-button-left': true, 'active': bill_type == 1 }"
					@click="changeCate(2)">增值税普通发票（电子）</div>
					<div :class="{ 'el-radio-button': true, 'el-radio-button-right': true, 'active': bill_type == 2 }"
						@click="changeCate(1) ">增值税专用发票（电子）</div>
				</div>
				<el-form   label-width="100px" class="demo-ruleForm" >
					<el-form-item label="" label-width="30px" prop="resource">
						<el-radio-group v-model="attr_type">
							<el-radio :disabled="bill_type == 2" :label="1">个人/非企业单位</el-radio>
							<el-radio :disabled="bill_type == 2" :label="2">企业</el-radio>
						</el-radio-group>
					</el-form-item>

				</el-form>
			<el-form :model="ruleForm"  v-show="attr_type === 1 && bill_type === 1"  :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"  >
				
				<el-form-item label="邮箱地址" prop="email">
					<el-input v-model="ruleForm.email"></el-input>
				</el-form-item>	
				<el-form-item label="发票抬头" prop="title">
					<el-input v-model="ruleForm.title"></el-input>
				</el-form-item>

				<div style="text-align: center;">
					<el-button type="primary" @click="submitForm('ruleForm')">申请开票</el-button>
				</div>
			</el-form>
			<el-form :model="ruleForm1"  v-show="attr_type === 2 && bill_type === 1"  :rules="rules1" ref="ruleForm1" label-width="100px" class="demo-ruleForm1"  >

			
			<el-form-item label="发票抬头" prop="title">
				<el-input v-model="ruleForm1.title"></el-input>
			</el-form-item>
			<el-form-item label="单位税号" prop="tax">
				<el-input v-model="ruleForm1.tax"></el-input>
			</el-form-item>
			<el-form-item label="邮箱地址"    prop="email">
				<el-input v-model="ruleForm1.email"></el-input>
			</el-form-item>
			<div  class="texttip" >以下内容选择填</div>
		

			<el-form-item label="开户银行" prop="bank">
				<el-input v-model="ruleForm1.bank"></el-input>
			</el-form-item>
			<el-form-item   label="银行账号" prop="bank_number">
				<el-input v-model="ruleForm1.bank_number"></el-input>
			</el-form-item>
			<el-form-item    label="单位地址" prop="company_address">
				<el-input v-model="ruleForm1.company_address"></el-input>
			</el-form-item>
			<el-form-item    label="单位电话" prop="company_tel">
				<el-input v-model="ruleForm1.company_tel"></el-input>
			</el-form-item>
			
			<div style="text-align: center;">
				<el-button type="primary" @click="submitForm1('ruleForm1')">申请开票</el-button>
			</div>
			</el-form>
		<el-form :model="ruleForm2"  v-show="attr_type === 2 && bill_type === 2"  :rules="rules2" ref="ruleForm2" label-width="100px" class="demo-ruleForm2"  >

			
			<el-form-item label="发票抬头" prop="title">
				<el-input v-model="ruleForm2.title"></el-input>
			</el-form-item>
			<el-form-item label="单位税号" prop="tax">
				<el-input v-model="ruleForm2.tax"></el-input>
			</el-form-item>

			<el-form-item label="开户银行" prop="bank">
				<el-input v-model="ruleForm2.bank"></el-input>
			</el-form-item>
			<el-form-item     label="银行账号" prop="bank_number">
				<el-input v-model="ruleForm2.bank_number"></el-input>
			</el-form-item>
			<el-form-item    label="单位地址" prop="company_address">
				<el-input v-model="ruleForm2.company_address"></el-input>
			</el-form-item>
			<el-form-item   label="单位电话" prop="company_tel">
				<el-input v-model="ruleForm2.company_tel"></el-input>
			</el-form-item>
			<div  class="texttip">以下是发票发送信息</div>
			<el-form-item   label="电子邮件地址" prop="email">
				<el-input v-model="ruleForm2.email"></el-input>
			</el-form-item>
			<el-form-item  label="联系方式" prop="accept_tel">
				<el-input v-model="ruleForm2.accept_tel"></el-input>
			</el-form-item>
			<!--<el-form-item label="收件地址" prop="accept_address">
				<el-input v-model="ruleForm2.accept_address"></el-input>
			</el-form-item>
			-->
			<div style="text-align: center;">
				<el-button type="primary" @click="submitForm2('ruleForm2')">申请开票</el-button>
			</div>
		</el-form>

		</el-dialog>

	</div>
</template>

<script>
import { buyrecord } from "@/api/member"
import { downloadByOrder, deleteByOrder ,applyInvoice} from "@/api/orderpay"

export default {
	data() {
		// 自定义验证规则
		var validatePhoneNumber = (rule, value, callback) => {
			const phoneRegex = /^1\d{10}$/; // 假设中国大陆手机号正则
			const telephoneRegex = /^(?:\d{3,4}-)?\d{6,8}$/; // 假设座机号正则
			if (value.match(phoneRegex) || value.match(telephoneRegex)) {
				callback(); // 验证通过
			} else {
				callback(new Error('请输入正确的手机号或座机号')); // 验证失败
			}
		};
		var validateCardNumber = (rule, value, callback) => {
			const cardRegex = /^\d{16,20}$/; // 假设中国大陆手机号正则
	
			if (value.match(cardRegex)) {
				callback(); // 验证通过
			} else {
				callback(new Error('请输入正确的手机号或座机号')); // 验证失败
			}
		};

		return {
			form: [],
			price: 103,
			dialogFormVisible: false,
			multipleSelection: [],
			ids: '',
			page: 1,
			size: 20,
			total: 0,
			tableData: [],
			bill_type:1,
			attr_type:1,
			ruleForm: {
				title: '',
				email: '',
			
			},
			ruleForm1: {
				title: '',
				tax: '',
				bank: '',
				bank_number: '',
				company_address: '',
				company_tel: ''
			},
			ruleForm2: {
			
				title: '',
				tax: '',
				bank: '',
				bank_number: '',
				company_address: '',
				company_tel: '',
				email:'',
				accept_tel:'',
				//accept_address:''
			},
			rules: {

				title: [
					{ required: true, message: '请输入公司抬头'},
					{ min: 3, max: 25, message: '公司名称长度在 3 到 25 个字符',}
				],	
				email: [
					{ required: true, message: '邮箱不能为空' },
					{ email:true, message: '请正确输入邮箱地址' }
				],

			},
			rules3: {

				
				
				title: [
					{ required: true, message: '请输入公司抬头'},
					{ min: 3, max: 25, message: '公司名称长度在 3 到 25 个字符',}
				],	
				email: [
					{ required: true, message: '邮箱不能为空' },
					{ email:true, message: '请正确输入邮箱地址' }
				],

				},
			
			rules1: {
				email: [
					{ required: true, message: '邮箱不能为空' },
					{ email:true, message: '请正确输入邮箱地址' }
				],
				title: [
					{ required: true, message: '请输入公司抬头' },
					{ min: 3, max: 25, message: '公司名称长度在 3 到 25 个字符' }
				],
				tax: [
					{ required: true, message: '请输入公司税号' }
				]
			},
			rules2: {
			
				title: [
					{ required: true, message: '请输入公司抬头'},
					{ min: 3, max: 25, message: '公司名称长度在 3 到 25 个字符' }
				],
				tax: [
					{ required: true, message: '请输入公司税号'}
				],
				bank: [
					{ required: true, message: '开户行不能为空' }
				],
				bank_number: [
					{ required: true, message: '银行卡号不能为空'}
				],
				company_address: [
					{ required: true, message: '公司地址不能为空'},
				],
				company_tel: [
					{ required: true, message: '公司电话不能为空'},
					{ validator: validatePhoneNumber }
				],
				email:[
					{ required: true, message: '收件地址不能为空'},
				],
				accept_tel:[
					{ required: true, message: '收件人电话不能为空'},
				],
				//accept_address:[
				//	{ required: true, message: '收件人地址不能为空'},
				//],
		},

		}
	},
	mounted() {
		this.getbuyrecord()
	},
	watch:{
		
	},
	methods: {
		changeCate(cate){
			
			
			if(cate ===  2){
					this.bill_type = 1 ; 
			
				}else{
					this.bill_type = 2; 
					this.attr_type = 2;
				
				}
		},
		submitForm(){
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					//提交成交
					const params = {
					    order_ids:this.ids,
						bill_type:this.bill_type,
						attr_type:this.attr_type,
						title:this.ruleForm.title,
						email:this.ruleForm.email
					}

					applyInvoice(params).then(res=>{
						this.getbuyrecord()
						this.dialogFormVisible = false
						this.$message.success('申请开票成功')
					})

				} else {

					return false;
				}
				});
		},

		submitForm1(){
			this.$refs.ruleForm1.validate((valid) => {
				if (valid) {
					//提交成交
					//提交成交
					const params = {
					    order_ids:this.ids,
						bill_type:this.bill_type,
						attr_type:this.attr_type,
						title:this.ruleForm1.title,
						email:this.ruleForm1.email,
						tax:this.ruleForm1.tax,
						bank:this.ruleForm1.bank,
						bank_number:this.ruleForm1.bank_number,
						company_tel:this.ruleForm1.company_tel,
						company_address:this.ruleForm1.company_address,
					}

					applyInvoice(params).then(res=>{
						this.getbuyrecord()
						this.dialogFormVisible = false
						this.$message.success('申请开票成功')
					})

				} else {
					// alert('验证失败');
					return false;
				}
				});
		},

		submitForm2(){
			this.$refs.ruleForm2.validate((valid) => {
				if (valid) {
					//提交成交
					const params = {
					    order_ids:this.ids,
						bill_type:this.bill_type,
						attr_type:this.attr_type,
						title:this.ruleForm2.title,
						tax:this.ruleForm2.tax,
						bank:this.ruleForm2.bank,
						bank_number:this.ruleForm2.bank_number,
						company_tel:this.ruleForm2.company_tel,
						company_address:this.ruleForm2.company_address,
						email:this.ruleForm2.email,
						//accept_address:this.ruleForm2.accept_address,
						accept_tel:this.ruleForm2.accept_tel,
					}
					applyInvoice(params).then(res=>{
						this.getbuyrecord()
						this.dialogFormVisible = false
						this.$message.success('申请开票成功')
					})


				} else {
					// alert('验证失败');
					return false;
				}
				});
		},


		
		validateBankCard() {
			this.$refs.bankCardForm.validate((valid) => {
				if (valid) {
					alert('银行卡号有效');
				} else {
					alert('银行卡号无效');
					return false;
				}
			});
		},
	

		changebill(row) {
			switch (row.bill_status) {
				case 0:
					this.ids = row.id
					this.price = row.actual_price
					this.toApply()
					break;
				case 1:

					break;
				case 2:

					break;
			}
		},

		hebing() {
			console.log('val', this.multipleSelection)
			if (this.multipleSelection.length < 1) {
				this.$message.info('请选择至少1个需要开票的订单')
				return
			}
			const ids = []
			let price = 0
			this.multipleSelection.forEach((v, i) => {
				ids.push(v.id)
				price = price + v.actual_price
			})
			this.price = price
			this.ids = ids.join(',')
			this.toApply()

		},
		toApply() {
			this.dialogFormVisible = true
		},

		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		// status=‘CheckOk' 默认选中
		defaultChecked() {
			var that = this;
			that.$nextTick(() => {
				this.tableData.forEach((v, i) => {
					if (v.can_bill === false) {
						this.$refs.table.toggleRowSelection(v, false);
					}
				});
			});
		},
		//判断勾选
		selectable(row, index) {
			if (row.can_bill !== true) {
				return false; // 禁用
			} else {
				return true; //不禁用
			}
		},
		// status=‘CheckFail' 该行背景为灰色
		tableRowStyle({ row, rowIndex }) {
			let rowBackground = {};
			if (row.can_bill !== false) {
				rowBackground.background = '#6d6d6d';
				rowBackground.color = "#fff";
			} else {
				rowBackground.background = "#333";
				rowBackground.color = "#fff";
			}
			return rowBackground;
		},

		//产出
		deleteRow(row) {
			const params = {
				order_no: row.order_no
			}
			var that = this

			deleteByOrder(params).then(res => {

				this.$message.success(res.message)
				that.getbuyrecord()


			})
		},

		//重新下载功能
		repeatdownload(row) {
			const params = {
				order_no: row.order_no
			}

			downloadByOrder(params).then(res => {
				if (res.data) {
					// this.$confirm('文件已经生成完毕,现在下载吗？', '下载提示', {
					// 	confirmButtonText: '确定',
					// 	cancelButtonText: '取消',
					// 	type: 'notice'
					// }).then(()=>{
					window.location.href = res.data;
					// })

				}
			})
		},


		handleSizeChange(val) {
			this.size = val
			this.getbuyrecord()
		},
		handleCurrentChange(val) {
			this.page = val
			this.getbuyrecord()
		},
		handleClick(row) {
			console.log(row);
		},
		getbuyrecord() {
			const params = {
				page: this.page,
				size: this.size
			}
			buyrecord(params).then(res => {
				this.total = res.data.total
				this.tableData = res.data.list


				this.defaultChecked() // 该方法可以在拿到表格数据后进行调用
			})
		}
	},

}
</script>

<style lang="scss" scoped>
.el-form-item{
 margin-bottom: 20px;
}

::v-deep .el-dialog__body {
	padding-top: 10PX;
	padding-right: 40PX;
}
::v-deep .el-dialog__title{
	font-weight: bold;
}
.canbill{
	color:#999;	
	cursor: not-allowed;
}
.texttip{
	text-align: left;
	margin-left: 100px;
	margin-bottom: 10px;
	font-family: PingFangSC, PingFang SC;
	font-weight: 400;
	font-size: 12px;
	position: relative;
	top:-5px;
}

.type {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 10px;
}
.customWidth{
	width: 380px;
	height: 375px;
}

.jine {
	width: 100%;
	display: flex;
	justify-content: center;
	height: 40px;

	.jinebox {
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 16px;
		color: #000000;
		line-height: 22px;
		text-align: center;
		font-style: normal;

		.price {
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 16px;
			color: #FF0000;
			line-height: 22px;
			text-align: center;
			font-style: normal;
		}
	}

}

.demo-ruleForm {
	text-align: left;
}

.el-radio-button-left {
	color: #000000;
	font-size: 14px;
	font-family: PingFangSC, PingFang SC;
	text-align: center;
	width: 233px;
	line-height: 32px;
	background: #FFFFFF;
	border-radius: 4px 0px 0px 4px;
	border: 1px solid #D9D9D9;

	cursor: pointer;
}

.el-radio-button-right {
	color: #000000;
	font-size: 14px;
	font-family: PingFangSC, PingFang SC;
	text-align: center;
	width: 233px;
	line-height: 32px;
	background: #FFFFFF;
	border-radius: 0px 4px 4px 0px;
	border: 1px solid #D9D9D9;
	cursor: pointer;
}

.active {
	border: 1px solid #00A3E4;
	color: #00A3E4;
}


.innerbox {
	position: relative;
}

.hebingbox {
	cursor: pointer;
	width: 86px;
	line-height: 32px;
	background: #00A3E4;
	border-radius: 4px;
	font-family: PingFangSC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #FFFFFF;

	text-align: center;
	position: absolute;
	top: -46PX;
	right: 20px;
}

.buyrecord {
	width: 100%;
	// min-height: calc(100vh - 180px);
	// background: #fff;
}

.actionbox {
	display: flex;
	justify-content: flex-end;

	.del {
		color: red;
	}
}

.iconf {
	display: inline-block;
	line-height: 18px;
	// width:50px;
	font-size: 10px;
	color: orange;
	border: solid 1px orange;
	border-radius: 3px;
	text-align: center;
	padding: 0 5px;
}
::v-deep .el-button{
	padding: 0;
	
	width: 128px;
}
::v-deep .el-button--primary{
	height: 32px;
	line-height: 32px;
	span{
		width: 128px;
		height: 32px;
		background: #28A7E1;
		border-radius: 4px;
		line-height: 32px;
	}
}

// .osloading {
//     position: fixed;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     background-color: rgba(255, 255, 255, 49%);
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     z-index: 2000;
//     .el-icon-loading {
//         display: inline-block;
//         height:48px;
//         width:  48px;
//         background: url('/assets/icon/scanloading.png') no-repeat; //引入你需要的图片
//         background-size: 48px 48px;
//         // background-position-x: 48%;
//         animation: rotating 2s linear infinite;
//     }
// }

// @keyframes rotating {
//     0% {
//         transform: rotate(0deg);
//     }

//     100% {
//         transform: rotate(1turn);
//     }
// }</style>
import { render, staticRenderFns } from "./byrecord.vue?vue&type=template&id=807c00da&scoped=true&"
import script from "./byrecord.vue?vue&type=script&lang=js&"
export * from "./byrecord.vue?vue&type=script&lang=js&"
import style0 from "./byrecord.vue?vue&type=style&index=0&id=807c00da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "807c00da",
  null
  
)

export default component.exports